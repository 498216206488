import React from 'react'

import { Router } from "@reach/router"

import PageArticlesPreview from 'components/pageDataPreview/PageArticlesPreview'

const ArticlePreview = () => (
  <Router basepath={`/ru/article_preview`}>
    <PageArticlesPreview path={'/:slug'} lang={'ru'} />
  </Router>
)

export default ArticlePreview
