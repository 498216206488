import React, { useEffect } from "react"

import { useStore } from "effector-react"

import articleStore from "state/articleStore"

import PageNewsDetail from "components/PageNewsDetail/PageNewsDetail"
import Layout from "components/Layout/Layout"

const Index = ({ slug }) => {
  const { detail, list } = articleStore

  const article = useStore(detail.store)
  const articlesList = useStore(list.store)

  useEffect(() => {
    detail.fetch(slug).then(data => {
      if (data.program.goal) {
        list.fetch({ program__goal__in: data.program.goal.id })
      } else {
        list.fetch({
          program_id__or__programs__id__in: [
            data.program.id,
            ...data.programs,
          ].join(","),
        })
      }
    })
  }, [slug])

  if (!article || !article.id) return null

  return (
    <Layout color="white">
      <PageNewsDetail
        article={article}
        articlesList={articlesList}
        slug={slug}
      />
    </Layout>
  )
}

export default Index
